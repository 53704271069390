<template>
    <div v-click-outside-modal="closeDropdown">
        <button class="flex items-center" id="user-menu" aria-haspopup="true" @click="toggle">
            <slot name="title">Open</slot>
        </button>

        <transition name="slide-fade">
            <div class="origin-top-right absolute right-0 mt-3 rounded shadow-sm text-left bg-white min-w-[250px] px-1 py-3" role="menu" aria-orientation="vertical" aria-labelledby="user-menu" v-show="open">
                <a href="#" class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline" role="menuitem" @click.prevent="redirect('myProfile.personal')">
                    <i class="bi bi-person mr-3"></i>
                    My Profile
                </a>
                <a href="#" class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline" role="menuitem" @click.prevent="showChangePasswordModal">
                    <i class="bi bi-key mr-3"></i>
                    Change Password
                </a>
                <a href="#" class="block px-3 py-1 text-body rounded hover:bg-gray-100 hover:no-underline" role="menuitem" @click.prevent="logout">
                    <i class="bi bi-box-arrow-right mr-3"></i>
                    Sign out
                </a>
            </div>
        </transition>
    </div>
</template>
<script>
import debounce from "debounce";
import { mapState, mapActions, mapGetters } from "vuex";
import ChangePasswordModal from "@/views/components/Modal/changePassword";
import { EventBus } from "@/event-bus";
import ClickOutsideModal from "@/directives/click-outside-modal";

export default {
    directives: {
        clickOutsideModal: ClickOutsideModal
    },
    name: "Dropdown",
    data() {
        return {
            open: false,
            showChangePassword: false,
        };
    },
    computed: {
        ...mapState(["session"]),
        ...mapGetters(["infoEmployee"]),
    },
    methods: {
        ...mapActions(["logoutUser"]),
        toggle() {
            this.open = !this.open;
        },
        
        closeDropdown() {
            this.open = false;
        },

        redirect: function (route) {
            if (this.$route.name !== route) {
                this.$router.push({ name: route });
            }
        },

        deleteSession: function () {
            this.logoutUser().then(() => {
                this.isLoading = false;
                setTimeout(window.location.reload.bind(window.location), 500);
            });
        },

        logout: debounce(async function () {
            this.deleteSession();
        }, 200),

        doSomething: debounce(function () {
            alert("focus lost");
        }, 750),

        showPasswordModal: function () {
            window.scrollTo(0, 0);
            this.showChangePassword = true;
        },

        showChangePasswordModal() {
            EventBus.$emit("open-modal", ChangePasswordModal, {
                profileInfo: this.infoEmployee,
            });
        },

        closeModals: function () {
            this.showChangePassword = false;
        },
    },
};
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all .18s ease-in-out;
}

.slide-fade-leave-active {
    transition: all .18s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to
{
    transform: translateY(-15px);
    opacity: 0;
}
</style>