<template>
  <div class="relative z-[51]" v-click-outside-modal="closeNotifications">
      <span v-if="this.getComponentMessages['unread'].length"
            class="absolute block right-1 px-1 bg-red-600 text-white text-xs font-bold rounded-full">
        {{ this.getComponentMessages['unread'].length }}
      </span>
    <button v-if="!isActive" @click.prevent="toggleNotifications"
            class="text-xl p-1 mx-2 hover:text-c-primary"
            v-b-tooltip.hover.top="'Messages Center'">
      <i class="bi bi-chat-left-text"></i>
    </button>
    <button v-if="isActive" @click.prevent="toggleNotifications"
            class="text-xl p-1 mx-2 hover:text-c-primary">
      <i class="bi bi-chat-left-text"></i>
    </button>
    <transition name="slide-fade">
      <div
        v-if="isActive"
        class="flex flex-column absolute top-12 right-0 bg-white rounded-sm overflow-hidden
        shadow-md min-w-[300px] bg-white shadow-lg divide-y-[1px]"
        id="message-notifications"
      >
        <div class="h-10 flex flex-row items-center divide-x-[1px]">
          <button
            @click.prevent="toggleFilter('unread')"
            class="w-1/2 text-center justify-center  h-full"
            :class="{'bg-gradient-to-t from-[#1e4ed8] to-[#07b6d5] text-white': activeFilter === 'unread'}"
          >
            Unread
          </button>
          <button
            @click.prevent="toggleFilter('all')"
            class="w-1/2 text-center justify-center h-full"
            :class="{'bg-gradient-to-t from-[#1e4ed8] to-[#07b6d5] text-white': activeFilter === 'all'}"
          >
            All
          </button>
        </div>
        <div class="messages-list divide-y w-full overflow-auto max-h-72"
             v-if="filteredMessages.length && componentIsReady">
          <div id="messagesContainer" class="hover:bg-gray-100 hover:cursor-pointer pr-3"
               v-for="msg in filteredMessages" :key="msg.message_mast_id">
            <div v-if="parseInt(msg.Message_Status) === MessageStatus.ACTIVE"
                 @click.prevent="parseInt(msg.Message_Status) === MessageStatus.ACTIVE ? goView(msg.message_mast_id) : goView()"
                 :id="msg.message_mast_id" class="massage-item text-left py-3">
              <div class="flex">
                <div class="flex px-3 items-center justify-between w-full">
                  <div class="flex flex-row items-center">
                    <i
                      :class="(parseInt(msg.Message_Type) === MessageStatus.INCOMPLETE ? 'bi bi-person bg-green-100 text-green-600':'bi bi-broadcast bg-red-100 text-red-600')+' flex items-center justify-center w-6 h-6 text-sm rounded-full text-center mr-3'"></i>
                    <div>
                      <p class="text-base leading-none mb-0 capitalize">{{ msg.Title }}</p>
                      <span v-if="msg.AckRequired === 1 && msg.accion_type === 'acknowledge'"
                            class="text-xs leading-3 block text-gray-400">
                        Acknowledged
                      </span>
                      <span v-else-if="msg.AckRequired === 1"
                            class="text-xs leading-[13px] block text-gray-400">
                        Requires acknowledgement
                      </span>
                      <span
                        class="text-xs leading-[13px] block text-gray-400">
                        {{ new Date(msg.CreatedAt).toDateString() }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-center"
                     v-if="getComponentMessages['unread'].map(x => {return x.message_mast_id;}).includes(msg.message_mast_id)">
                  <span v-if="msg.Priority === 0"
                        class="mx-1 text-center content-center w-4 h-4 rounded-full bg-gradient-to-b from-cyan-500 to-blue-700 align-middle"/>
                  <svg v-if="msg.Priority === 1" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                       class="size-6 text-red-600">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="this.componentIsReady && (this.filteredMessages.length === '0')">
          <p class="text-center text-gray-400 my-3">Loading...</p>
        </div>
        <div v-else>
          <p class="text-center text-gray-400 my-3">No messages found</p>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
//import auth from "@/config/user"
import {mapGetters, mapState, mapActions} from 'vuex'
import ClickOutsideModal from "@/directives/click-outside-modal";

export default {
  name: "",
  components: {},
  directives: {
    clickOutsideModal: ClickOutsideModal
  },
  data() {
    return {
      isActive: false,
      activeFilter: 'unread',
      componentIsReady: false,
      loadingMsg: true,
      MessageStatus: {
        ACTIVE: 1,
        INCOMPLETE: 2,
        INACTIVE: 3,
      },
    }
  },
  validations: {},
  computed: {
    ...mapState(['messages']),
    ...mapGetters(['getComponentMessages']),
    filteredMessages() {
      return this.getComponentMessages[this.activeFilter];
    }
  },
  created() {
    this.refreshNotifications();
  },
  methods: {
    ...mapActions([
      'fetchMessagesOnComponent',
    ]),
    refreshNotifications() {
      this.componentIsReady = false;
      this.fetchMessagesOnComponent().then(() => {
        this.componentIsReady = true;
      });
    },
    toggleFilter(filter) {
      this.activeFilter = filter;
      this.refreshNotifications();
    },
    goLink: function () {
      this.$router.push({name: 'msg.create'});
    },
    goView: function (id) {
      this.refreshNotifications();
      if (id) {
        this.closeNotifications();
        window.location.href = '/messages/view/' + id;
      }
    },
    toggleNotifications: function () {
      this.refreshNotifications()
      this.isActive = !this.isActive;
    },
    closeNotifications: function () {
      this.isActive = false;
    }
  },
  mounted() {
    let title = 'Messages Center';
    this.$emit('set-title', title);
  }
};
</script>
<style scoped>
.bi::before {
  line-height: 1.8;
}

.slide-fade-enter-active {
  transition: all .18s ease-in-out;
}

.slide-fade-leave-active {
  transition: all .18s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}

</style>