<template>
  <nav class="bg-white relative rounded-lg shadow-md">
    <div class="w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex h-full">
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <notifications-messages :key="this.messageNotification"/>
            <notificationsButton :key="this.notificationKey"></notificationsButton>
            <clocker-button/>
            <dropdown>
              <div slot="title" class="group flex flex-row items-center">
                <span class="mr-3 group-hover:text-[#e73c4f]"> {{this.username}} </span>
                <img class="h-10 w-10 rounded-full" src="https://placekitten.com/300/300" alt="">
              </div>
            </dropdown>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" @click="toggleUserMenu">
            <span class="sr-only">Open main menu</span>
            <!--
              Heroicon name: menu
              Menu open: "hidden", Menu closed: "block"
            -->
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <!--
              Heroicon name: x
              Menu open: "block", Menu closed: "hidden"
            -->
            <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!--
      Mobile menu, toggle classes based on menu state.
      Open: "block", closed: "hidden"
    -->
    <div class="hidden md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <a href="#" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Dashboard</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Reports</a>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <img class="h-10 w-10 rounded-full" src="https://placekitten.com/g/200/300" alt="userProfile">
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-white">Tom Cooks</div>
            <div class="text-sm font-medium leading-none text-gray-400">tom@example.com</div>
          </div>
          <button class="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-500 hover:text-white focus:outline-none ">
            <span class="sr-only">View notifications</span>
            <!-- Heroicon name: bell -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Your Profile</a>

          <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Settings</a>
          <hr>
          <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 bg-gray-50 hover:text-white hover:bg-gray-700">Sign out</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Dropdown from "@/views/Utilities/Dropdown";
import clockerNavbarButton from '@/views/Utilities/clockerNavbarButton'
import desktopNotification from '@/views/Utilities/desktopNotifications'
import notificationsMessages from "../messages/components/notificationsMessages";
export default {
    name: "Navbar",
    components:{
      Dropdown,
      'clocker-button':clockerNavbarButton,
      'notificationsButton': desktopNotification,
      'notifications-messages': notificationsMessages
    },
  data(){
    return{
       notificationKey: 0,
       messageNotification: 100,
       showUsersMenu:false,
    }
  },
  props:{
    username:String
  },
  methods:{
    updateMessageNotifications:function(){
      this.messageNotification ++;
    },
    updateNotifications:function(){
      this.notificationKey ++;
    },
    toggleUserMenu:function(){
      this.showUsersMenu = !this.showUsersMenu;
    },
    toggleSidebar:function(){
      this.$emit('toggle');
    },
    toggleMenu:function() {
      //this.
    }
  }
}
</script>