<template>
    <div class="bg-white rounded-[10px] shadow-sm pb-2">
        <div class="flex items-center gap-x-6 px-4">
            <div><i class="bi bi-search text-gray-400 py-3"></i><input type="search" placeholder="Buscar..." class="pl-3 py-3" /></div>
            <div class="flex-1"></div>
            <div>
                <span class="uppercase text-sm w-48">
                    {{toggleLabel}}
                </span>
                <label class="switch align-middle">
                    <input checked v-on:click="showAllMessages(1 , $event)" type="checkbox">
                    <span class="slider round"></span>
                </label>
            </div>
            <div>
                <button @click.prevent="goLink" class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-1 rounded-full"><i class="bi bi-chat-left-text mr-2"></i>New Message</button>
            </div>
        </div>
        <table class="w-full text-left">
            <tbody v-if="getMessages.length && !loadingMsg">
                <tr :id="msg.ID" v-for="msg in getMessages" :key="msg.ID" class="border-top group">
                    <td class="text-center pl-4 group-hover:bg-gray-100" width="5%"><i :class="(msg.Message_Type == MessageType.TARGETED ? 'bi bi-person bg-green-100 text-green-600':'bi bi-broadcast bg-red-100 text-red-600') + ' inline-block w-8 h-8 text-lg rounded-full'"></i></td>
                    <td class="hover:cursor-pointer group-hover:bg-gray-100" @click.prevent="msg.Message_Status == this.MessageStatus.ACTIVE ? goView(msg.ID) : goView()">{{msg.Title}}<span class="text-gray-300 ml-2">#{{msg.ID}}</span></td>
                    <td class="text-gray-400 group-hover:bg-gray-100">From: {{msg.StartTime | moment("MM-DD-YYYY")}} - To: {{msg.EndTime | moment("MM-DD-YYYY")}}</td>
                    <td class="text-center group-hover:bg-gray-100">
                        <span v-if="msg.Message_Status == MessageStatus.ACTIVE" class="inline-block bg-green-100 text-green-600 font-semibold text-xs px-3 py-1 rounded-full">Active</span>
                        <span v-else-if="msg.Message_Status == MessageStatus.INCOMPLETE" class="inline-block bg-amber-100 text-amber-600 font-semibold text-xs px-3 py-1 rounded-full">Incomplete</span>
                        <span v-else-if="msg.Message_Status == MessageStatus.INACTIVE" class="inline-block bg-red-100 text-red-600 font-semibold text-xs px-3 py-1 rounded-full">Inactive</span>
                    </td>
                    <td :id="msg.ID" :key="msg.ID" class="pr-4 relative group-hover:bg-gray-100" width="1%">
                        <button @click.stop="openActions(msg.ID)" :class="( idActive == msg.ID ? 'bg-gray-200':'')" class="w-8 h-8 rounded-full hover:bg-gray-300"><i class="bi bi-three-dots-vertical"></i></button>
                        <ul :class="(idActive==msg.ID ? '':'hidden')" class="ddmenu text-sm absolute top-full right-6 bg-white rounded-[6px] py-2 -mt-4 mb-0 shadow whitespace-nowrap z-10">
                            <!--TODO actually add edit function-->
                            <!--<li><button class="w-full text-left hover:bg-gray-100 px-3"><i class="bi bi-pencil pr-2"></i> Edit</button></li>-->
                            <li><button :id="msg.ID" :key="msg.ID"  @click.prevent="deleteMsg(msg.ID)" class="w-full text-left hover:bg-red-100 text-red-500 px-3"><i class="bi bi-trash3 pr-2"></i> Delete</button></li>
                        </ul>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="loadingMsg">
              <tr>
                <td colspan="5" class="text-center text-gray-400">Loading...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" class="text-center text-gray-400">No records found.</td>
              </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    //import auth from "@/config/user"
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        name: "",
        components: { },
        data(){
            return {
                MessageType: {
                    BROADCAST: 1,
                    TARGETED: 2
                },
                MessageStatus: {
                    ACTIVE: 1,
                    INCOMPLETE: 2,
                    INACTIVE: 3,
                },
                loadingMsg: true,
                all: 0,
                idActive: 0,
                toggleLabel: 'active',
            }
        },
        validations: {
            
        }, 
        computed:{
            ...mapState(['messages']),
            ...mapGetters(['getMessages'])
        },
        created(){
            this.loadingMsg = true;
            this.fetchAllMessages(this.MessageStatus.ACTIVE).then(()=>{
                this.loadingMsg = false;
            });
        },
        methods:{
            ...mapActions([
                'fetchAllMessages',
                'deleteMessage'
            ]),
            goLink:function(){
                this.$router.push({ name: 'msg.create' });
            },
            goView:function(id){
                if(id) {
                    this.$router.push({ path: '/messages/view/'+id });
                }
            },
            showAllMessages:function(value,event) {
                this.toggleLabel = this.toggleLabel?.toLowerCase() === 'active' ? 'incomplete only' : 'active';
                this.all = event.target.checked ? this.MessageStatus.ACTIVE : this.MessageStatus.INCOMPLETE;
                this.loadingMsg = true;
                this.fetchAllMessages(this.all).then(()=>{
                    this.loadingMsg = false;
                });
            },
            openActions:function(id) {
                this.idActive = id==this.idActive ? 0:id
            },
            deleteMsg: function(value){
                this.$confirm("", "Are you sure you want to delete the message?", "question").then((result) => {
                    if (result){                
                        this.deleteMessage(value).then(()=>{
                            location.reload();
                        });
                    }
                })
            },
        },
        mounted(){
            let title = 'Messages Manager';
            this.$emit('set-title',title);
        }
    };
</script>
<style scoped>
    .bi::before {
        line-height: 1.8;
    }
    td {
        padding: 10px;
    }
</style>