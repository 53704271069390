<template>
    <div class="bg-white rounded-[10px] shadow-sm p-10 text-left overflow-auto">
        <div class="flex">
            <div class="flex-1">
                <div class="border rounded">
                    <div class="p-3 border-bottom">
                        <label class="text-xs mb-0 uppercase">Title</label>
                        <input ref="title" type="text" class="w-full " v-model="titlemsg" placeholder="..." />
                    </div>
                    <div class="p-3 border-bottom">
                        <label class="text-xs mb-0 uppercase">Body</label>
                        <textarea required class="w-full resize-none" rows="5" v-model="bodymsg" placeholder="..."></textarea>
                    </div>
                    <div class="p-3 border-bottom">
                        <label class="text-xs mb-0 uppercase">URL (Optional)</label>
                        <input required type="url" class="w-full" v-model="urlmsg" placeholder="..." />
                    </div>
                    <div class="p-3">
                        <label class="text-xs mb-2 uppercase">Picture (Optional)</label>
                        <label class="block">
                            <upload-files @changed="handleImages" :isMultiple='isMultiple' :maxFiles="1" maxError="the max size of the files must be less than 2MB"></upload-files>
                    </label>
                    </div>
                </div>
            </div>
            <div class="pl-5">
                <div class="pb-3">
                    <label class="text-xs uppercase block">Message Type</label>
                    <div class="radiogroup flex text-center">
                        <input type="radio" name="type" v-model="message_type" :value="messageTypes.BROADCAST" id="type-broadcasted" checked @change="changeMsgType" /><label class="flex-1" for="type-broadcasted">Broadcasted</label>
                        <input type="radio" name="type" v-model="message_type" :value="messageTypes.TARGETED" id="type-targeted" @change="changeMsgType" /><label class="flex-1" for="type-targeted">Targeted</label>
                    </div>
                </div>
                <div class="border p-3 mb-3 rounded-[15px]" v-if="parseInt(this.message_type) === messageTypes.TARGETED ">
                    <div>
                        <div class="pb-1 flex flex-row items-center w-xl" v-for="(crit, index) in criteria" :key="index" >
                            <div class="w-full flex flex-row">
                                <button class="text-xs font-semibold uppercase my-auto p-1 rounded-md flex flex-row" :disabled="index === 0" @click="changeClause"
                                :class="{
                                    'bg-gray-100 text-gray-300' : index > 1,
                                    'bg-gray-500 text-white' : index === 1
                                }">
                                    {{crit.CLAUSE}}
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-2 h-2 my-auto ml-1" :hidden="index === 0 || index > 1">
                                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                <div class="mx-2">
                                    <select class="border w-full rounded-full py-2 px-3" v-model="crit.FILTER" required @change="resetValue(index)">
                                        <option value="null" hidden>Select criteria</option>
                                        <option class="rounded-[15px] p-1" value="Location">Site</option>
                                        <option class="rounded-[15px] p-1" value="Department">Department</option>
                                        <option class="rounded-[15px] p-1" value="Campaign">Campaign</option>
                                        <option class="rounded-[15px] p-1" value="Title">Job Title</option>
                                    </select>
                                </div>
                                <button class="text-xs font-semibold uppercase my-auto p-1 rounded-md flex flex-row bg-gray-500 text-white" 
                                @click="changeOperator(index)" :hidden="crit.FILTER === null">
                                    <div class="text-xs uppercase my-auto whitespace-nowrap" :hidden="crit.OPERATOR === '!='">
                                        is
                                    </div>
                                    <div class="text-xs uppercase my-auto whitespace-nowrap" :hidden="crit.OPERATOR === '='">
                                        is not
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-2 h-2 my-auto ml-1">
                                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                <div class="mx-2 w-40" v-if="crit.FILTER === 'Location'">
                                    <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                        <option value="null" hidden>Select criteria</option>
                                        <option class="rounded-[15px] p-1" v-for=" (item, index) in getLocations" :value="item" :key="index">{{ item.toUpperCase() }}</option>
                                    </select>
                                </div>
                                <div class="mx-2 w-40" v-if="crit.FILTER === 'Department'">
                                    <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                        <option value="null" hidden>Select criteria</option>
                                        <option v-for="department in getDepartments" :key="department.id" :value="department.label">{{department.label}}</option>
                                    </select>
                                </div>
                                <div class="mx-2 w-40" v-if="crit.FILTER === 'Campaign'">
                                    <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                        <option value="null" hidden>Select criteria</option>
                                        <option v-for="(campaign, index) in getAllCampaigns" :key="index" :value="campaign">{{campaign}}</option>
                                    </select>
                                </div>
                                <div class="mx-2 w-40" v-if="crit.FILTER === 'Title'">
                                    <select class="border w-full rounded-full py-2 px-3" v-model="crit.VALUE" required>
                                        <option value="null" hidden>Select criteria</option>
                                        <option v-for="item in getTitles" :key="item" :value="item">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <button class="text-gray-700 flex flex-row" @click="removeCriteria(index)" title="Remove filter" :hidden="index === 0">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                        <button class="text-gray-700 flex flex-row" @click="addCriteria" title="Add new filter">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-xs uppercase my-auto p-1">
                                Add filter
                            </span>
                        </button>
                    </div>
                </div>
                <div class="pb-3">
                    <label class="text-xs uppercase block">Priority</label>
                    <div class="radiogroup flex text-center">
                        <input type="radio" name="priority" v-model="priority" value="0" id="priority-normal" checked /><label class="flex-1" for="priority-normal">Normal</label>
                        <input type="radio" name="priority" v-model="priority" value="1" id="priority-urgent" /><label class="flex-1" for="priority-urgent">Urgent</label>
                    </div>
                </div>
                <div class="pb-3">
                    <label class="text-xs uppercase block">Subject</label>
                    <div>
                        <select class="border w-full rounded-full py-2 px-3" v-model="subject">
                            <option value="1">Newsletter</option>
                            <option value="2">Process Update</option>
                            <option value="3">Aviso</option>
                        </select>
                    </div>
                </div>
                <div class="pb-3">
                    <label class="text-xs uppercase block">StartTime - EndTime</label>
                    <div class="flex border rounded-full">
                        <input type="date" v-model="starttime" class="flex-1 bg-transparent text-center py-2 px-3 border-right cursor-pointer"/>
                        <input type="date" v-model="endtime" class="flex-1 bg-transparent text-center py-2 px-3 cursor-pointer" />
                    </div>
                </div>
                <div>
                    <label class="text-xs uppercase block">ACK required</label>
                    <div class="radiogroup flex text-center">
                        <input type="radio" name="ack" v-model="ack" value="1" id="ack-yes" /><label class="flex-1" for="ack-yes">Yes</label>
                        <input type="radio" name="ack" v-model="ack" value="0" id="ack-no" checked /><label class="flex-1" for="ack-no">No</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-4">
            <button class="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-full" @click.prevent="sendMessage(1)"><i class="bi bi-send mr-2"></i>Send Message</button>
            <button class="border-2 border-green-600 hover:border-green-700 text-green-600 px-6 py-2 rounded-full mr-4" @click.prevent="sendMessage(2)"><i class="bi bi-pencil mr-2"></i>Draft</button>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from "vuex";
    import uploadFiles      from '@/views/Utilities/upload_files/index'
    
    export default {
        name: "",
        components: { 
            'upload-files' : uploadFiles,
        },
        data(){
            return {
                criteria:[{ CLAUSE:'where', FILTER: null, OPERATOR: '=', VALUE: null }],
                messageTarget: 'site',
                message_type: 1,
                titlemsg: '',
                bodymsg: '',
                urlmsg: '',
                picture: [],
                priority: 0,
                subject: 1,
                starttime: '',
                endtime: '',
                ack: 0,
                isMultiple: false,
                fileImage: null,
                messageTargetTypes: {
                    SITE:       'site',
                    DEPARMENT:  'department',
                    CAMPAIGN:   'campaign',
                    JOBTITLE:   'jobTitle',
                },
                messageTypes:{
                    BROADCAST: 1,
                    TARGETED: 2,
                },
                idxSiteSelect: 0,
                idxDepartmentSelect: 0,
                idxCampaignSelect: 0,
                idxJobTitleSelect: 0,
                campaignIsLoading: false,
                jobTitleIsLoading: false,
                isLoading: true,
            }
        },
        validations: {
            
        }, 
        computed:{
            ...mapGetters([
                'getLocations',
                'getDepartments',
                'getAllCampaigns',
                'getTitles'
            ]),

            filterSentence(){
                if(parseInt(this.message_type) === this.messageTypes.BROADCAST) return "where EmpStatus = 'Active'";
                let sentence = '';
                for(let i = 0; i < this.criteria?.length; i++){
                    sentence = sentence + this.criteria[i].CLAUSE + ' ' + this.criteria[i].FILTER + ' ' + this.criteria[i].OPERATOR + ' ' + "'" + this.criteria[i].VALUE + "'" + ' ';
                }
                return sentence?.trim();
            },

            filterErrors(){
                let errors = 0;
                for(let j = 0; j < this.criteria?.length; j++){
                    if(this.criteria[j].FILTER === 'null' || this.criteria[j].FILTER === null || this.criteria[j].VALUE === 'null' || this.criteria[j].VALUE === null) errors+=1;
                }

                return errors;
            }
        },
        created() {
            this.fetchSites();
            this.fetchDepartments();
            this.fetchCampaigns();
            this.fetchTitles();
        },
        methods:{
            ...mapActions([
                'fetchSites',
                'fetchDepartments',
                'newMessage',
                'fetchCampaigns',
                'fetchTitles'
            ]),

            addCriteria(){
                let cls = this.criteria?.length > 2 ? this.criteria[1].CLAUSE : 'and';
                this.criteria.push({ CLAUSE: cls, FILTER: null, OPERATOR: '=', VALUE: null });
            },

            removeCriteria(i){
                this.criteria.splice(i, 1);
            },

            changeClause(){
                for (var i = 1; i < this.criteria?.length; i++){
                    let isOr = this.criteria[i].CLAUSE === 'or';
                    this.criteria[i].CLAUSE = isOr ? 'and' : 'or';
                }
            },

            changeOperator(i){
                let is = this.criteria[i].OPERATOR === '=';
                this.criteria[i].OPERATOR = is ? '!=' : '=';
            },

            resetValue(i){
                this.criteria[i].VALUE = null;
            },

            changeMsgType:function(e) {
                this.message_type = e.target.value;
            },
            readAsDataURL(file) {
                return new Promise(function (resolve, reject) {
                    let fr = new FileReader();
                    fr.onload = function () {
                        resolve(fr.result);
                    };
                    fr.onerror = function () {
                        reject(fr);
                    };
                    fr.readAsDataURL(file);
                });
            },
            handleImages(files){
                let readers = [];
                this.fileImage = files[0];
                readers.push(this.readAsDataURL(files[0]));
                Promise.all(readers).then((values) => {
                    this.images = values;
                });
            },

            sendMessage:function(status) {
                if(!this.titlemsg){
                    this.$fire({
                        type: 'error',
                        title: 'Missing fields',
                        text: 'Please set a Title for the message',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    return false;
                }

                if(!this.bodymsg){
                    this.$fire({
                        type: 'error',
                        title: 'Missing fields',
                        text: 'Please set a Body for the message',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    return false;
                }

                if(this.filterErrors > 0 && parseInt(this.message_type) === this.messageTypes.TARGETED){
                    this.$fire({
                        type: 'error',
                        title: 'Missing fields',
                        text: 'Please fill the empty fields in the targeted message filter',
                        showConfirmButton: false,
                        timer: 2500,
                    })
                    return false;
                }

                let loader = document.getElementById("pro-loading");
                if (loader){
                    loader.style.display = "flex";
                }

                let dataObject = new FormData();
                dataObject.append('filter',     this.filterSentence);
                dataObject.append('image',      this.fileImage);
                dataObject.append('type',       this.message_type);
                dataObject.append('title',      this.titlemsg);
                dataObject.append('body',       this.bodymsg);
                dataObject.append('url',        this.urlmsg);
                dataObject.append('priority',   this.priority);
                dataObject.append('subject',    this.subject);
                dataObject.append('starttime',  this.starttime);
                dataObject.append('endtime',    this.endtime);
                dataObject.append('ack',        this.ack);
                dataObject.append('status',     status);

                this.newMessage(dataObject).then(() =>{
                    this.$fire({
                        type: 'success',
                        title: 'The message has been sent successfully',
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(()=>{
                        let loader = document.getElementById("pro-loading");
                        if (loader){
                            loader.style.display = "none";
                        }
                        this.$router.push({ name: 'msg.manager' });
                    })
                }).catch(()=>{
                    this.status = 'error';
                        this.$fire({
                            type: 'warning',
                            title: 'Something wrong',
                            text: 'Please report to a system administrator',
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    
                })
            }
        },
        watch:{
        },
        mounted(){
            let title = 'New Message';
            this.$emit('set-title',title);
        }
    };
</script>
<style scoped>
.radiogroup {
    font-size: 90%;
    border-radius: 30px;
    border: 2px solid #2563eb;
}
.radiogroup input[type="radio"] {
    display: none;
}
.radiogroup input[type="radio"] + label {
    display: inline-block;
    color: #2563eb;
    line-height: 2;
    padding: 0 15px;
    border-radius: 20px;
    margin: 0;
    cursor: pointer;
}
.radiogroup input[type="radio"]:checked + label {
    color: #fff;
    background-color: #2563eb;
}
</style>