<template>
    <div class="relative" v-click-outside-modal="onlyCloseNotifications">
        <span v-if="this.getUnreadMessages.length" class="absolute block -top-1 -right-1 px-1 bg-red-600 text-white text-xs font-bold rounded-full">{{ this.getUnreadMessages.length }}</span>
        <button v-if="!isActive" @click.prevent="toggleNotifications" class="text-xl p-1 hover:text-c-primary" v-b-tooltip.hover.top="'Notifications'">
            <i class="bi bi-bell"></i>
        </button>
        <button v-if="isActive" @click.prevent="toggleNotifications" class="text-xl p-1 hover:text-c-primary">
            <i class="bi bi-bell"></i>
        </button>
        <transition name="slide-fade">
            <notifications v-if="isActive"/>
        </transition>
    </div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
import NotificationMenu from "@/views/notifications/layout";
import ClickOutsideModal from "@/directives/click-outside-modal";

export default {
    directives: {
        clickOutsideModal: ClickOutsideModal
    },
    components: {
        notifications: NotificationMenu,
    },
    data(){
        return{
            isActive: false,
            unreadNotifications: 0,
            username: '',
        }
    },
    computed:{
        ...mapState([
            "notifications"
        ]),
        ...mapGetters([
            "getUnreadMessages",
        ]),
    },
    created: function(){
        this.updateMessages();
        this.getUsername();
        window.Echo.channel("ChatNotifications").listen(".unread-message", e => {
            if(e.EmployeeReceiver === this.username){
                this.fetchUnreadNotifications().then(()=>{
                    this.$emit('updateNotifications', true);
                });
            }
        });
        window.Echo.channel("TicketsNotifications").listen(".ticket-updated", e => {
            if(e.EmployeeReceiver === this.username ){
                this.fetchUnreadNotifications().then(()=>{
                    this.$emit('updateNotifications', true);
                });
            }
        });
        window.Echo.channel("LogOutNotification").listen(".logOut-User", e => {
            if(e.EmployeeReceiver === this.username ){
                sessionStorage.clear();
                setTimeout(window.location.reload.bind(window.location), 500);
            }
        });
        window.Echo.channel("PositionsNotifications").listen(".new-position", e => {
            this.$toasted.show("New Position: " +e.position.title, {
                theme: "toasted-primary", 
                icon : 'error',
                position: "bottom-right", 
                duration : null,
                action : [
                    {
                        text : 'Open Positions',
                        onClick : (e, toastObject) => {
                            this.$router.push({name:'positions.index'});
                            toastObject.goAway(0);
                        }
                    }, {
                        text : 'Ok',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    }
                ],
            });
        });
    },
    methods:{
         ...mapActions([
          'changeNotificationStatus',
          'fetchUnreadNotifications',
        ]),
        getUsername () {
            let EmpID = JSON.parse(sessionStorage.getItem("EmpID"));
            if (EmpID) { 
                this.username = EmpID; 
            }
        },
        toggleNotifications () {
             this.isActive = !this.isActive;
        },
        onlyCloseNotifications () {
             this.isActive = false;
        },
        updateMessages () {
            this.fetchUnreadNotifications();
        },
    },
}
</script>
<style>
.slide-fade-enter-active {
    transition: all .18s ease-in-out;
}

.slide-fade-leave-active {
    transition: all .18s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to
{
    transform: translateY(-15px);
    opacity: 0;
}
</style>